<template>
	<div class="groupEdit el-content">
		<a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
			<a-form-item label="角色名称"><a-input v-model:value="form.title" /></a-form-item>
			<a-form-item label="分配权限">
				<a-tree
					v-if="menu && menu.length > 0"
					checkable
					defaultExpandAll
					:tree-data="menu"
					:defaultCheckedKeys="form.menus"
					:show-line="true"
					:replaceFields="{ children: 'children', title: 'menu_name', key: 'menu_id' }"
					v-model:checkedKeys="form.menus"
				></a-tree>
			</a-form-item>
			<a-form-item :wrapper-col="{ offset: 2 }">
				<a-button type="primary" @click="saveRole">保存</a-button>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import authModel from '@/api/saas/auth';
import router from '@/router'
export default {
	setup(){
		const state = reactive({
			form: {
				title: '',
				menus: [],
				status: 1
			},
			menu: []
		})

		const opt = useRoute().query
		if( opt.id ){
			authModel.getRoleDetail(opt.id,res=>{
				state.form.id = opt.id
				state.form.title = res.title;
				state.form.menus = res.menus;
				state.form.status = res.status;
			})
		}

		authModel.getAuthMenu(res=>{
			let menu = neatenMenu(res.web.menu);
			let farm_admin = neatenMenu(res.farm_admin.menu);
			state.menu = [...menu, ...farm_admin];
		})

		//给添加好的菜单新增一个 “label”属性， el-tree 这个组件要展示这个属性
		function neatenMenu(data) {
			data.forEach(item => {
				item.menu_id = '' + item.menu_id;
				if (item.children) {
					neatenMenu(item.children);
				}
			});
			return data;
		}

		const saveRole = ()=>authModel.addOrEditRole(state.form,()=>router.go(-1))

		return{
			...toRefs(state),
			neatenMenu,
			saveRole
		}
	}
}
</script>

<style lang="scss">
.kd-perm-li {
	width: 68%;
	height: auto;
	border: 1px solid #f1f1f1;
	padding: 0 0 0 2%;
	margin-bottom: 10px;

	&-children {
		width: 100%;
		margin-left: 30px;
		&-operation {
			width: 100%;
			margin-left: 30px;
		}
	}
}

.groupEdit {
	.ant-tree>.ant-tree-treenode-switcher-open{
		display: flex;
		>span{
			margin-top: 14px;
		}
		>.ant-tree-child-tree{
			>.ant-tree-treenode-switcher-open{
				display: flex;
				flex-wrap: wrap;
				>span{
					margin-top: 7px;
				}
				
				>.ant-tree-child-tree{
					display: flex;
					flex-wrap: wrap;
					align-items: flex-start;
					>.ant-tree-treenode-switcher-open>span{
						margin-left: 10px;
					}
				}
			}
		}
	}
	
	
	.el-tree-node {
		font-size: 13px;
	}
	.el-tree-node__content {
		width: 140px !important;
		float: left !important;
		margin-left: 5px !important;
	}

	.el-tree > div {
		margin: 30px 0 !important;
		background: #fff !important;
	}

	.el-tree-node.is-current > .el-tree-node__content:hover {
		background: #fff !important;
	}

	.el-tree-node__content:hover {
		/* background: #fff !important; */
	}

	.el-tree-node__expand-icon.is-leaf {
		display: none;
	}

	.el-tree-node__expand-icon.expanded {
		display: none;
	}

	.el-tree > div {
		margin: 10px 0 !important;
		background: #fff !important;
	}

	.el-tree-node.is-current > .el-tree-node__content:hover {
		background: #fff !important;
	}

	.el-tree-node__content:hover {
		/* background: #fff !important; */
	}

	.el-tree-node__expand-icon.is-leaf {
		display: none;
	}

	.el-tree-node__expand-icon.expanded {
		display: none;
	}

	.el-tree-node__content {
		width: 140px !important;
		float: left !important;
		margin-left: 5px !important;
	}

	.el-tree > div {
		padding: 5px 0 !important;
		height: auto;
		overflow: hidden;
		background: #fff !important;
		border-bottom: #f0f0f0 dotted 1px;
	}
	.el-tree-node__expand-icon.is-leaf {
		display: none;
	}

	.el-tree-node__expand-icon.expanded {
		display: none;
	}

	.el-tree-node__children {
		border-left: #ccc dotted 1px;
	}

	.el-tree-node:hover {
		background: #f5f7fa !important;
	}
}
</style>
